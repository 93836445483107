import React from 'react';
import { useAsync } from 'react-async';
import InstaFeedWorker from './InstaFeed.worker.js';
import Masonry from 'react-masonry-css';
import classNames from 'classnames';
import { useWindowSize } from 'react-use';
import 'react-medium-image-zoom/dist/styles.css';

export interface IInstaFeed {
  data: FeedDatum[];
  paging: Paging;
}

interface Paging {
  cursors: Cursors;
  next: string;
}

interface Cursors {
  before: string;
  after: string;
}

interface FeedDatum {
  id: string;
  media_type: string;
  media_url: string;
  permalink: string;
  timestamp: string;
  caption: string;
  thumbnail_url?: string;
}

const InstaIcon = ({ className }) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" className={className}>
      <path
        d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16Z"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path
        d="M17.5 6.51L17.51 6.49889"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const InstaFeed = ({ className = '' }) => {
  const { width } = useWindowSize();
  const worker = React.useMemo(() => typeof window === 'object' && new InstaFeedWorker(), []);
  const [items, setItems] = React.useState<FeedDatum[]>([]);
  const feedAction = useAsync<IInstaFeed>({
    deferFn: React.useCallback(([url]) => worker?.getFeed?.(url), [worker]),
    onResolve: (result) => {
      if (result?.data) {
        setItems((prev) =>
          prev.concat(result.data ?? [])?.filter((item) => /\#wlpo/i.test(item.caption))
        );
      }
      if (result.paging?.next) {
        feedAction.run(result.paging?.next);
      }
    },
  });
  React.useEffect(() => {
    feedAction.run();
  }, []);

  return (
    <Masonry
      breakpointCols={width >= 960 ? 3 : width >= 640 ? 2 : 1}
      className={classNames('flex w-auto px-4 xl:px-12', className)}
      columnClassName="bg-clip-padding px-2"
    >
      {items.map(({ id, thumbnail_url, media_url, permalink }) => (
        <a href={permalink} target="_blank">
          <div className="relative w-full h-auto my-4 transition-all translate-y-0 cursor-pointer hover:-translate-y-1 sm:my-4">
            <img id={id} src={thumbnail_url || media_url} width={600} height={600} className="rounded" />
            <InstaIcon className="absolute top-2 right-2" />
          </div>
        </a>
      ))}
    </Masonry>
  );
};
