import React from 'react';
import { Layout } from '../layouts/Layout';
import { SEO } from '../components/SEO';
import { InstaFeed } from '../components/InstaFeed';

export default function Archive({}) {
  return (
    <Layout>
      <SEO title="Archive" />
      <div className="mt-16">
        <InstaFeed className="w-full h-auto mx-auto overflow-hidden" />
      </div>
    </Layout>
  );
}
