
				var addMethods = require("../../node_modules/workerize-loader-wp5/dist/rpc-wrapper.js")
				var methods = ["getFeed"]
				module.exports = function() {
					var w = new Worker(__webpack_public_path__ + "built-[1].d1c045db11be3af4cc0a.worker.js", { name: "built-[1].[contenthash].worker.js" })
					URL.revokeObjectURL(__webpack_public_path__ + "built-[1].d1c045db11be3af4cc0a.worker.js");
					addMethods(w, methods)
					
					return w
				}
			